import classNames from "classnames";
import {useSelector} from "react-redux";

import {Link as RouterLink, useLocation, useMatch, useResolvedPath} from "react-router";
import Span from "~/components/common/Span";
import {getSlugInfo} from "~/reducers/navigation";

import classes from "./Link.module.pcss";
import buttonClasses from "./Button.module.pcss";
import {addRetURL, applyTpl, classPrefix, createStylesSelector, sanitizePath} from "~/lib";
import type {LinkProps} from "~/@types/components/common/LinkProps";
import {ComponentPropsWithoutRef, ReactNode} from "react";
import Icon from "~/components/common/Icon";


export default function Link({
    to = "/",
    slug = "",
    native = false,
    asButton = false,
    bold = false,
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    withRetURL = false,
    slugTplParams = {},
    ...props
}: LinkProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, buttonClasses, classes]);
    const Component: "a" | typeof RouterLink = native ? "a" : RouterLink;
    const anchorProps: ComponentPropsWithoutRef<"a"> = {};
    const linkProps: ComponentPropsWithoutRef<typeof RouterLink> = {to};
    let linkChildren = props.children;

    const location = useLocation();

    if (slug) {
        const {path: slugPath, title: slugTitleTpl} = useSelector(getSlugInfo)(slug);
        const slugTitle = slugTitleTpl ? applyTpl(slugTitleTpl, slugTplParams) : "";
        if (slugPath) {
            to = sanitizePath(`${slugPath}/${to || ""}`);
            if (!native) {
                linkProps.relative = "path";
            }
        }
        if (slugTitle && typeof linkChildren === "undefined") {
            linkChildren = slugTitle;
        }
    }

    if (props.target === "_blank") {
        linkChildren = <>
            {linkChildren}
            <Icon styles={styles} className={styles("external").toString()} ico="Export" mode="auto" />
        </>;
    }

    if (withRetURL) {
        to = addRetURL(to, location, withRetURL);
    }

    const componentProps: Omit<LinkProps, "className"> = {};
    let isActive: boolean = false;
    if (native) {
        anchorProps.href = to;
        anchorProps.children = linkChildren as ReactNode;
    } else {
        linkProps.to = to;
        const resolved = useResolvedPath(to);
        isActive = useMatch({ path: resolved.pathname, end: false }) !== null;
    }

    const lProps = native ? anchorProps : linkProps;

    return <Span
        {...props}
        {...lProps}
        deleteProps={["styles"]}
        componentProps={componentProps}
        Component={Component}
        styles={styles}
        className={classNames(
            classPrefix("link"),
            propsClassName,
            styles(
                "link",
                asButton && "button",
                bold && "bold",
                isActive && "active"
            ))}
    >{linkChildren}</Span>;
}