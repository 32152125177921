import classNames from "classnames";

import classes from "./Loader.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import type {LoaderProps} from "~/@types/components/common/LoaderProps";
import {ReactNode} from "react";

export default function Loader({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    type = "spinner",
    size = "default",
    animate = true,
    position = "relative",
    loadingText = "loading"
}: LoaderProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    let loader: ReactNode = null;
    switch (type) {
        case "spinner":
        default:
            loader = <span className={"" + styles("spinner")}></span>;
            break;
        case "text":
            loader = <>
                {loadingText}
                <span className={"" + styles("dot")}>.</span>
                <span className={"" + styles("dot")}>.</span>
                <span className={"" + styles("dot")}>.</span>
            </>;
            break;
        case "none":
            loader = null;
            break;
    }

    const loaderClassNames: string[] = [
        "loader",
        `type-${type}`,
        `position-${position}`,
        `size-${size}`,
    ];
    if (animate) {
        loaderClassNames.push("animate");
    }

    return loader ? <span className={classNames(classPrefix("loader"), propsClassName, styles(...loaderClassNames))}>
        <span className={"" + styles("container")}>
            {loader}
        </span>
    </span> : null;
}
