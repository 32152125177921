import classNames from "classnames";

import classes from "./ErrorMessage.module.pcss";
import {classPrefix, createStylesSelector, isDev} from "~/lib";
import type {ErrorMessageProps} from "~/@types/components/common/ErrorMessageProps";
import type {DetailsProps} from "~/@types/components/common/DetailsProps";
import Details from "~/components/common/Details";
import Span from "~/components/common/Span";

function getErrorDetails(error: Omit<ErrorMessageProps["error"], "string">, skipErrCodes: boolean): DetailsProps["details"] {
    const errDetails: DetailsProps["details"] = [];
    let errStatus: string = "", errCode: string = "";
    if (!skipErrCodes) {
        if ("code" in error) {
            errCode = error.code + "";
            errDetails.push({
                field: "code",
                content: error.code + ""
            });
        }
        if ("status" in error) {
            errStatus = error.status + "";
            if (typeof errStatus !== errCode) {
                errDetails.push({
                    field: "status",
                    content: errStatus
                });
            }
        }
    }
    if ("data" in error) {
        const errData = error.data;
        if (errData && typeof errData === "object") {
            const errDataDetails = getErrorDetails(errData, skipErrCodes)
                .filter(({field}) =>
                    !(field === "status" && errStatus !== "")
                    && !(field === "code" && errCode !== "")
                );
            errDetails.push(...errDataDetails);
        }
        if (isDev()) {
            errDetails.push({
                field: "data",
                content: typeof error.data === "object" ? JSON.stringify(errData) : errData + ""
            });
        }
    }
    if ("error" in error) {
        errDetails.push({
            field: "error",
            content: error.error + ""
        });
    }
    if ("message" in error) {
        errDetails.push({
            field: "message",
            content: error.message + ""
        });
    }

    return errDetails;
}

export default function ErrorMessage({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    error,
    prefix = null,
    suffix = null,
    children,
    skipErrCodes = false,
    noWrap = false
}: ErrorMessageProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    if (error) {
        if (typeof error === "string") {
            children = <Span className={styles("message")}>{error}</Span>;
        } else if (typeof error === "object") {
            if ("map" in error && typeof error.map === "function") {
                noWrap = true;
                children = error.map((e, k) => <ErrorMessage key={k} error={e} prefix={prefix} suffix={suffix} styles={propsStyles} classes={propsClasses} className={propsClassName} skipErrCodes={skipErrCodes}/>);
                prefix = null;
                suffix = null;
            } else {
                const errDetails: DetailsProps["details"] = getErrorDetails(error, skipErrCodes);
                children = <Details
                    className={styles("details")}
                    styles={styles}
                    details={errDetails}
                    separator=", "
                />;
            }
        }
    }
    return noWrap
        ? <>{prefix}{children}{suffix}</>
        : <div className={classNames(classPrefix("error-message"), propsClassName, styles("error-message"))}>{prefix}{children}{suffix}</div>;
}
